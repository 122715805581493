<template>
  <v-container
    style="height: calc(100vh - 60px); overflow-y: auto"
    class="py-0"
    fluid
  >
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>Câu hỏi</v-card-title>
          <v-card-text style="height: calc(100vh - 220px);overflow-y: auto">
            <v-text-field
              label="Câu số"
              v-model="order"
              type="number"
              placeholder="Nhập số câu"
              :min="1"
            ></v-text-field>
            <v-select
              v-model="level"
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              label="Level"
              placeholder="Nhập số câu"
            ></v-select>
            <div style="border-bottom:1px solid black" class="mb-2">
              <p style="line-height:32px;font-weight:bold">
                Nội dung câu hỏi
                <v-btn
                  @click="content = null"
                  style="float:right"
                  class="ml-2"
                  fab
                  dark
                  x-small
                  color="green"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
                <v-btn
                  @click="readImageFromClipboard('question')"
                  style="float:right"
                  fab
                  dark
                  x-small
                  color="blue"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </p>
              <img v-if="content" :src="content" />
            </div>
            <div
              v-for="choice in choices"
              :key="choice.order"
              style="border-bottom:1px solid black"
              class="mb-2"
            >
              <p
                :style="{
                  'line-height': '32px',
                  'font-size': '14px',
                  'font-weight': 'bold',
                  color: choice.isCorrect ? 'red' : 'unset'
                }"
                class="mb-2"
              >
                Phương án {{ choice.order }}
                <v-btn
                  @click="choice.image = null"
                  style="float:right"
                  class="ml-2"
                  fab
                  dark
                  x-small
                  color="green"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
                <v-btn
                  @click="readImageFromClipboard(choice.order)"
                  style="float:right"
                  fab
                  dark
                  x-small
                  color="blue"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </p>
              <img v-if="choice.image" :src="choice.image" alt="" />
              <v-text-field
                v-model="choice.content"
                hide-details
                dense
                style="width:66%"
                :disabled="!!choice.image"
                label="Đáp án"
              ></v-text-field>
              <v-switch
                v-model="choice.isCorrect"
                dense
                label="Đáp án đúng"
              ></v-switch>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="$router.push('/lecturer/original-exams')"
              small
              color="blue"
              class="d-block mx-auto"
              >Quay lại</v-btn
            >
            <v-btn
              @click="update"
              small
              color="blue"
              :disabled="isLocked"
              class="d-block mx-auto"
              >Thêm mới/Chỉnh sửa</v-btn
            >
            <v-btn
              @click="isLocked = !isLocked"
              small
              color="blue"
              class="d-block mx-auto"
              icon
              ><v-icon
                >mdi-{{ isLocked ? "lock" : "lock-open-variant" }}</v-icon
              ></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="originalExam" cols="8">
        <v-card style="width:100%">
          <v-card-title
            >{{ originalExam.name }}
            <v-btn @click="exportOriginalExam" icon>
              <v-icon>mdi-download</v-icon></v-btn
            >
            <v-edit-dialog ref="file" v-if="!isLocked" @close="file = null">
              <v-icon class="mr-2">mdi-upload</v-icon>
              <template v-slot:input>
                <div class="text-center py-2">
                  <v-file-input
                    label="File"
                    prepend-icon=""
                    append-icon="mdi-file-outline"
                    @change="file = $event"
                    :value="file"
                    style="width: 250px"
                  ></v-file-input>

                  <v-btn
                    class="my-1"
                    color="blue"
                    :disabled="!file"
                    :loading="loading"
                    small
                    @click="importOriginalExam()"
                    >Lưu</v-btn
                  >
                  <v-btn
                    class="my-1"
                    color="blue"
                    @click="
                      file = null;
                      $refs.file.cancel();
                    "
                    small
                    >Hủy</v-btn
                  >
                </div>
              </template>
            </v-edit-dialog>
            <v-btn small @click="generatePdf">PDF</v-btn>
            <v-btn small @click="dialog1 = true">Thống kê</v-btn>
            <v-btn small :disabled="isLocked" @click="autoExamDialog = true"
              >Nhập đề tự động</v-btn
            >
            <v-btn small @click="examBoxDialog = true">Xem trước</v-btn>
          </v-card-title>
          <v-card-text style="height: calc(100vh - 175px);overflow-y: auto">
            <div v-for="question in originalExam.questions" :key="question.id">
              <h4 class="mb-2">
                Câu {{ question.order }} - Level {{ question.level }}
                <v-btn
                  :disabled="isLocked"
                  @click="addToForm(question)"
                  small
                  icon
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  :disabled="isLocked"
                  @click="deleteQuestion(question.id)"
                  small
                  icon
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
                <v-btn
                  small
                  text
                  v-if="!isLocked && originalExam.isGeneratedExams"
                  @click="showDialog(question)"
                  >Sửa đáp án đúng</v-btn
                >
              </h4>
              <img :src="question.content" alt="" />
              <div v-for="choice in question.choices" :key="choice.id">
                <p
                  :style="{
                    color: choice.isCorrect ? 'red' : 'unset',
                    fontSize: '14px'
                  }"
                  class="my-2"
                >
                  Phương án {{ choice.order }}:
                  <span v-if="choice.type === 'text'">{{
                    choice.content
                  }}</span>
                </p>
                <img :src="choice.image" v-if="choice.image" alt="" />
              </div>

              <v-divider class="my-2"></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <OriginalExam ref="html2Pdf" :exam="originalExam" />
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="py-2">
          Chỉnh sửa đáp án đúng
        </v-card-title>
        <v-card-text class="pt-0">
          <v-select
            v-model="correctChoices"
            multiple
            label="Câu trả lời đúng"
            :items="selectedQuestion.choices"
            item-value="id"
            :item-text="c => 'Phương án ' + c.order"
          ></v-select>
          <p class="my-0" style="color:red;font-size:14px;line-height:16px">
            Nếu đã cho sinh viên thi, cần tính lại điểm để cập nhật theo đáp án
            mới
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue" @click="dialog = false" text>Hủy</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue" @click="updateCorrectChoices" text>Lưu</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog1" max-width="420px">
      <v-card v-if="originalExam">
        <v-card-title class="py-2">
          Thống kê
        </v-card-title>
        <v-card-text class="pt-0">
          <p class="mb-0">Tên đề: {{ originalExam.name }}</p>
          <p class="mb-0">Học phần: {{ originalExam.subject.fullName }}</p>
          <p class="mb-0">Kỳ học: {{ originalExam.termId }}</p>
          <p class="mb-0">
            Kỳ thi: {{ originalExam.type === "M" ? "Giữa kỳ" : "Cuối kỳ" }}
          </p>
          <v-divider class="my-2"></v-divider>
          <p class="mb-0">Tổng số câu: {{ originalExamInfo.total }}</p>
          <p class="mb-0" v-for="n in 10" :key="n">
            Số câu mức {{ n }}: {{ originalExamInfo[`level${n}`].length }}
            <span v-show="originalExamInfo[`level${n}`].length > 0"
              >({{ originalExamInfo[`level${n}`] | transformArrayToText }})
            </span>
          </p>
          <v-divider class="my-2"></v-divider>
          <p class="mb-0">
            Số câu 4, 5, 6 phương án:
            {{ originalExamInfo.moreThanFourChoices }}
          </p>
          <p class="mb-0">
            Số câu 4 phương án: {{ originalExamInfo.fourChoices }}
          </p>
          <p class="mb-0">
            Số câu 5 phương án: {{ originalExamInfo.fiveChoices }}
          </p>
          <p class="mb-0">
            Số câu 6 phương án: {{ originalExamInfo.sixChoices }}
          </p>
          <v-divider class="my-2"></v-divider>
          <p class="mb-0">
            Số câu có phương án đúng:
            {{ originalExamInfo.hasCorrectChoices }}
          </p>
          <p class="mb-0">
            Số câu có 1 phương án đúng:
            {{ originalExamInfo.hasOneCorrectChoices }}
          </p>
          <p class="mb-0">
            Số câu có 2 phương án đúng:
            {{ originalExamInfo.hasTwoCorrectChoices }}
          </p>
          <p class="mb-0">
            Số câu có 3 phương án đúng:
            {{ originalExamInfo.hasThreeCorrectChoices }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AutoExamDialog
      v-if="autoExamDialog"
      v-model="autoExamDialog"
      @crop="autoExamCrop"
    ></AutoExamDialog>
    <ExamBoxDialog
      v-if="examBoxDialog && originalExam"
      v-model="examBoxDialog"
      :questions="originalExam.questions"
    ></ExamBoxDialog>
  </v-container>
</template>
<script>
const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

import OriginalExam from "@/components/pdf/OriginalExam";
import AutoExamDialog from "./AutoExamDialog.vue";
import ExamBoxDialog from "./ExamBoxDialog.vue";
import {
  createQuestion,
  show,
  deleteQuestion,
  importOriginalExam,
  updateCorrectChoices,
  deleteAllQuestion
} from "@/api/original-exam";

export default {
  components: {
    OriginalExam,
    AutoExamDialog,
    ExamBoxDialog
  },

  data() {
    return {
      order: 1,
      dialog: false,
      content: null,
      level: 1,
      file: null,
      loading: false,
      choices: [1, 2, 3, 4, 5, 6].map(n => ({
        order: n,
        content: "",
        image: null,
        isCorrect: false
      })),
      originalExam: null,
      isLocked: true,
      selectedQuestion: {
        choices: []
      },
      correctChoices: [],
      dialog1: false,
      autoExamDialog: false,
      autoExamDialog: false,
      examBoxDialog: false
    };
  },
  created() {
    this.getOriginalExam();
  },

  methods: {
    showDialog(question) {
      this.selectedQuestion = JSON.parse(JSON.stringify(question));
      this.dialog = true;
      this.correctChoices = this.selectedQuestion.choices
        .filter(item => item.isCorrect)
        .map(item => item.id);
    },

    async autoExamCrop({ questions: qs, saveOverwrite }) {
      const questions = qs.map(e => {
        return {
          level: e.level,
          content: e.image,
          meta: {},
          choices: e.answers.map(a => {
            return {
              content: null,
              image: a.image,
              type: "image",
              isCorrect: a.isCorrect,
              order: a.order,
              meta: {}
            };
          })
        };
      });

      const exam = this.originalExam;
      exam.questions = questions;
      const strJson = JSON.stringify(exam);
      const buf = Buffer.from(strJson);
      const blob = new Blob([buf.toString("base64")], { type: "text/plain" });
      const form = new FormData();
      form.append("file", blob, "bu.dat");
      if (saveOverwrite) {
        await deleteAllQuestion(this.$route.params.id);
      }

      await this.importOriginalExam(form);
    },

    async readImageFromClipboard(target) {
      this.$loader(true);
      try {
        const data = await navigator.clipboard.read();
        if (data.length === 0) throw "Clipboard đang trống";

        if (!data[0].types.includes("image/png"))
          throw "Clipboard không chứa hình ảnh";
        const blob = await data[0].getType("image/png");
        const image = await toBase64(blob);
        if (target === "question") this.content = image;
        else {
          this.choices[target - 1].image = image;
          this.choices[target - 1].content = "";
        }
        this.$loader(false);
      } catch (error) {
        console.log(error);
        this.$snackbar(error, "error");
      } finally {
        this.$loader(false);
      }
    },
    async update() {
      this.$loader(true);
      const form = {
        order: this.order,
        level: this.level,
        content: this.content,
        choices: this.choices
      };
      try {
        if (!this.content || !this.order)
          return this.$snackbar(
            "Chưa nhập số câu hoặc nội dung câu hỏi",
            "error"
          );
        await createQuestion(this.$route.params.id, form);

        this.clean();
      } catch (error) {
        console.log(error);
        this.$snackbar("Bạn không thể chỉnh sửa hay xóa lúc này", "error");
      } finally {
        this.$loader(false);
      }

      this.$loader(false);
    },
    async getOriginalExam() {
      try {
        const { data } = await show(this.$route.params.id);
        this.originalExam = data;
        if (this.originalExam.questions.length > 0)
          this.order =
            Math.max.apply(
              null,
              this.originalExam.questions.map(item => item.order)
            ) + 1;
      } catch (error) {
        this.$router.push("/canborade");
      }
    },
    async deleteQuestion(id) {
      try {
        this.$loader(true);
        await deleteQuestion(id);

        this.getOriginalExam();
      } catch (error) {
        this.$snackbar("Bạn không thể chỉnh sửa hay xóa lúc này", "error");
      } finally {
        this.$loader(false);
      }
    },
    async updateCorrectChoices() {
      try {
        this.$loader(true);
        await updateCorrectChoices(this.selectedQuestion.id, {
          correctChoices: this.correctChoices
        });
        this.dialog = false;
        this.getOriginalExam();
      } catch (error) {
        console.log(error);
      } finally {
        this.$loader(false);
      }
    },
    clean() {
      this.content = null;
      // this.level = 1;
      this.choices = [1, 2, 3, 4, 5, 6].map(n => ({
        order: n,
        content: "",
        image: null,
        isCorrect: false
      }));
      this.getOriginalExam();
    },
    addToForm(question) {
      this.order = question.order;
      this.content = question.content;
      this.level = question.level;
      this.choices = [1, 2, 3, 4, 5, 6].map(n => ({
        order: n,
        content: "",
        image: null,
        isCorrect: false
      }));
      for (const c of this.choices) {
        const choice = question.choices.find(item => item.order === c.order);
        if (choice) {
          c.content = choice.content;
          c.image = choice.image;
          c.isCorrect = choice.isCorrect;
        }
      }
    },
    async importOriginalExam(form = null) {
      try {
        if (form == null) {
          form = new FormData();
          form.append("file", this.file);
        }

        this.loading = true;
        await importOriginalExam(this.$route.params.id, form);
        this.loading = false;
        if (this.$refs.file) {
          this.$refs.file.cancel();
        }
        this.getOriginalExam();
      } catch (error) {
        console.log(error);
        this.$snackbar("Import không thành công");
      } finally {
        this.loading = false;
      }
    },
    async exportOriginalExam() {
      const endpoint =
        process.env.NODE_ENV === "development"
          ? `http://localhost:3333/api/original-exams/${this.$route.params.id}/export`
          : `/api/original-exams/${this.$route.params.id}/export`;
      window.open(endpoint);
    },
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    }
  },
  computed: {
    originalExamInfo() {
      if (this.originalExam)
        return {
          total: this.originalExam.questions.length,
          level1: this.originalExam.questions
            .filter(item => item.level === 1)
            .map(item => item.order),
          level2: this.originalExam.questions
            .filter(item => item.level === 2)
            .map(item => item.order),
          level3: this.originalExam.questions
            .filter(item => item.level === 3)
            .map(item => item.order),
          level4: this.originalExam.questions
            .filter(item => item.level === 4)
            .map(item => item.order),
          level5: this.originalExam.questions
            .filter(item => item.level === 5)
            .map(item => item.order),
          level6: this.originalExam.questions
            .filter(item => item.level === 6)
            .map(item => item.order),
          level7: this.originalExam.questions
            .filter(item => item.level === 7)
            .map(item => item.order),
          level8: this.originalExam.questions
            .filter(item => item.level === 8)
            .map(item => item.order),
          level9: this.originalExam.questions
            .filter(item => item.level === 9)
            .map(item => item.order),
          level10: this.originalExam.questions
            .filter(item => item.level === 10)
            .map(item => item.order),
          moreThanFourChoices: this.originalExam.questions.filter(
            item => item.choices.length >= 4
          ).length,
          fourChoices: this.originalExam.questions.filter(
            item => item.choices.length === 4
          ).length,
          fiveChoices: this.originalExam.questions.filter(
            item => item.choices.length === 5
          ).length,
          sixChoices: this.originalExam.questions.filter(
            item => item.choices.length === 6
          ).length,
          hasCorrectChoices: this.originalExam.questions.filter(
            item => item.choices.filter(c => c.isCorrect).length > 0
          ).length,
          hasOneCorrectChoices: this.originalExam.questions.filter(
            item => item.choices.filter(c => c.isCorrect).length === 1
          ).length,
          hasTwoCorrectChoices: this.originalExam.questions.filter(
            item => item.choices.filter(c => c.isCorrect).length === 2
          ).length,
          hasThreeCorrectChoices: this.originalExam.questions.filter(
            item => item.choices.filter(c => c.isCorrect).length === 3
          ).length
        };
    }
  },
  filters: {
    transformArrayToText(val) {
      let arr = [...val].sort((a, b) => a - b);
      let result = [];
      let transformedResult = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] != arr[i - 1] + 1) {
          result.push([arr[i]]);
        } else result[result.length - 1].push(arr[i]);
      }

      result.forEach(item => {
        if (item.length > 2) transformedResult.push(item);
        else transformedResult = transformedResult.concat(item);
      });

      transformedResult = transformedResult.map(item => {
        return item instanceof Array
          ? `${item[0]} - ${item[item.length - 1]}`
          : item;
      });
      return transformedResult.join(", ");
    }
  }
};
</script>
